<template>
  <div class="app de">
      <div class="hd">
        <span @click="$router.back()">返回</span>
        <div class="action">
          <div>
             <i class="iconfont icon-shoucang" v-if="issc"></i>
             <i class="iconfont icon-shoucang" v-else></i>
            <span v-if="issc" >已收藏</span>
            <span v-else @click="dosc">立即收藏</span>

          </div>
          <div>
<!--            <md-icon name="share" />-->
<!--            分享-->
          </div>
        </div>

      </div>
      <div class="ct">
        <md-scroll-view auto-reflow :scrolling-x="false">
          <h1>{{ article.title }}</h1>
          <div class="info">
            <span>来源: {{article.from}}</span>
            <span>阅读: {{article.reading}}</span>
            <span>时间: {{ article.addTime|format('YYYY.MM.DD') }}</span>
          </div>
          <div class="video" v-if="hasMedia">
            <video id="videoElement" v-if="isandroid" controls="controls"></video>

            <video-player v-else  style="height: 100%;"   class="video-player-box"
                            ref="videoPlayer"
                            :options="playerOptions"
                            :playsinline="true"
            >
            </video-player>
          </div>
          <div class="audio" v-if="article.music">
            <Mp3 :article="article"></Mp3>
          </div>
          <div class="book" v-if="article.pdf">
            <div id="viewer"></div>
            <div id="prev" class="arrow">‹</div>
            <div id="next" class="arrow">›</div>
          </div>
          <div class="content mct" v-html="article.content"></div>
          <div class="content" v-if="article.down">
            <div style="line-height: 2.5;">附件下载</div>
            <div v-for="item of article.down.split(',')">
              <a :href="item" target="_blank"  style="color:blue">
                {{ item.split("/").pop() }}
              </a>
            </div>

          </div>
          <div class="hots">
            <div class="getnewhot" @click="getHots">换一批</div>
            <div class="tuwen">
              <div class="twitem" v-for="item of hotList" :key="item.aid" @click="$router.replace({name:'detail',query:{id:item.aid}})">
                <div class="cover">
                  <img :src="item.cover" alt="">
                </div>
                <div class="info">
                  <div class="title">{{ item.title.slice(0,18) }}</div>
                  <div class="desc" style="color:#9b9b9b">{{item.contentSummary.slice(0,10)|htmlfilter}} </div>
                  <div class="detail">
                    <div class="author">{{ item.author }}</div>
                    <div class="time">{{ item.addTime |format('YYYY-MM-DD') }}</div>
                  </div>
                </div>
              </div>


            </div>
          </div>
        </md-scroll-view>
      </div>
    <navSlide />
  </div>



</template>

<script>
import flvjs from 'flv.js'
import Mp3 from "@/components/mp3";
import Epub from 'epubjs'

export default {
name: "List",
  components:{
    Mp3,

  },
  data(){
    return {
      options: {
        isLive: true,   //切换为直播流的时候必填
      },
      hasMedia:false,
      source:'',
      items: [
        {name: 1, label: '默认'},
        {name: 2, label: '全部'},
        {name: 3, label: '满减券'},
        {name: 4, label: '立减券'},
        {name: 5, label: '免息券'},
        {name: 6, label: '校园专享'},
        {name: 7, label: '夜间优惠'},
        {name: 8, label: '红包'},
      ],
      orderBy: '默认',
      article: {},
      issc:false,
      hotList:[],
      isandroid: true,
      playerOptions: {
        muted: true,
        autoplay: true, //如果true,浏览器准备好时开始回放。
        language: 'zh',
        playbackRates: [0.7, 1.0, 1.5, 2.0],
      },
    }
  },
  created(){

    if(flvjs.isSupported()){
      this.isandroid = true
    }else{
      this.isandroid = false
    }



  },
  mounted() {
    this.getData()
    this.checkSc()
    this.getHots()

  },
  watch:{
    '$route'(nv){

      try{
        this.getData()
        this.checkSc()
        this.getHots()
      } catch (e) {

      }

    }
  },
  methods:{
  async dosc(){
      // const uid = this.$store.state.UserInfo.uid;
      const uid = this.$store.state.userInfo.uid
      if(!uid) {
        this.$toast.info('请先登录')
        setTimeout(()=> this.$router.replace({name:'login'}),3000)
      }else{
        let res = await this.$api.post('/collection/add',{type:3,uid,tid:this.$route.query.id})
        const { success } = res.data;
        if(!success) return    this.$toast.failed('收藏失败');
        this.$toast.succeed('收藏成功');
        this.issc = true
      }

  },
    checkSc(){
      if(this.$store.state.userInfo.uid){
        this.$api.post('/collection/check',{uid:this.$store.state.userInfo.uid,type:3,tid:this.$route.query.id}).then((res)=>{
          const {success} =res.data;
          if(success) return this.issc =true
          this.issc = false
        })
      }

    },
    async getData(){
      const aid = this.$route.query.id;
       const res = await this.$api.post('/mapi/article/detail',{aid})
       this.article = res.data
      // 处理图片压缩
      this.$nextTick(()=>{
        let doms =  document.querySelectorAll('.mct img')
        console.log('doms',doms)
        for(const dom of doms){
          console.log(dom)
          dom.src = dom.src+'-wzmobile'
        }


        //电子书处理
        if(this.article.pdf){
          var params = URLSearchParams && new URLSearchParams(document.location.search.substring(1));
          var currentSectionIndex = (params && params.get("loc")) ? params.get("loc") : undefined;

          var book = Epub(this.article.pdf);
          var rendition = book.renderTo("viewer", {
            manager: "continuous",
            flow: "paginated",
            width: "100%",
            height: "100%",
            snap: true
          });
          var displayed = rendition.display(currentSectionIndex);
          displayed.then(function(renderer){
            // -- do stuff
          });
          var next = document.getElementById("next");
          next.addEventListener("click", function(){
            rendition.next();
          }, false);

          var prev = document.getElementById("prev");
          prev.addEventListener("click", function(){
            rendition.prev();
          }, false);
        }


      })

       // 视频处理
      if(this.article.video){
        this.hasMedia = true
        this.$nextTick(()=>{
          if(!this.isandroid) {
            this.playerOptions = {
              muted: true,
              language: 'zh',
              autoplay: false, //如果true,浏览器准备好时开始回放。
              playbackRates: [0.7, 1.0, 1.5, 2.0],
              sources: [{
                type: "video/mp4",
                src: this.article.video
                // src: "rtmp://8.135.85.155:8881/hls/500868544"
              }],
            }
          }else{

            if (flvjs.isSupported()) {
              var videoElement = document.getElementById('videoElement');
              console.log(videoElement)

              var flvPlayer = flvjs.createPlayer({
                type: this.article.flvvideo? 'flv': 'mp4',
                url: this.article.flvvideo ? 'http://static.1775.net.cn/'+this.article.flvvideo :this.article.video
              });
              flvPlayer.attachMediaElement(videoElement);
              flvPlayer.load();
              flvPlayer.play();
            }
          }

        })
      }else{
        this.hasMedia = false
      }





    },
    async getHots(){

      if(!this.$route.query.id) return false
       try{
         let res = await this.$api.post('/mapi/getHots',{id:this.$route.query.id});
         const { success,data} =res.data
         this.hotList = data
       } catch (e) {

       }



    }
  }
}
</script>

<style scoped lang="scss">

.main{
  height: 0;
  flex-grow: 1;
}
.de .content img{
  width: 80%;
  margin:0 auto;
}


.video video{
  height: 450px;
}

.video-player{
  height: 450px!important;
  video {
    height: 100%;
  }
}
#viewer{width:700px;height:80vh;box-shadow:0 0 4px #ccc;padding:10px 10px 0px 10px;margin:5px auto;background:white;}


  #viewer iframe {
    pointer-events: none;
  }

  ::v-deep{
    .video-js{
      height: 100%;
      width: 100%;
      position: relative;
    }
    .vjs-big-play-button{
      position: absolute;
      top:50%;
      left:50%;
      transform: translate(-50%,-50%);
    }
    .player-btns{
      width: 100%;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
        span {
          margin: 0 auto;
          display: inline-block;
          padding: 5px 10px;
          width: 150px;
          height: 40px;
          line-height: 40px;
          border: 1px solid #eee;
          background: #e1e1e1;
          border-radius: 10px;
          text-align: center;
          margin: 5px;
          cursor: pointer;
        }
        }
  }

</style>
