<template>
  <div class="music-box">

    <img :src="article.poster" style="object-fit: cover;width: 100%;height:100%" v-if="article.poster" />
    <img src="http://wz.wszqyg.co,/public/img/avatar.jpg" v-else />


    <div class="mask">
      <div class="mplayer" @click="play">
        <i class="fa">
          <span class="before"></span>
          <span class="after"> </span>
        </i>
        <svg
            class="loadingSvg"
            viewBox="0,0,50,50"
            style="
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                "
        >
          <circle></circle>
        </svg>
      </div>
      <div class="m-circle m-progress">
        <svg width="326" height="326" viewBox="0,0,326,326">
          <circle
              cx="163"
              cy="163"
              r="159"
              stroke-width="4"
              stroke="rgba(255, 206, 113, 0.2)"
              fill="rgba(0,0,0,.2)"
          ></circle>
          <circle
              class="a"
              cx="163"
              cy="163"
              r="159"
              stroke-width="3"
              stroke="rgba(255, 206, 113, 1)"
              fill="none"
              stroke-dasharray="0 999"
              transform="matrix(0,-1,1,0,0,326)"
          ></circle>
        </svg>
      </div>
      <div class="m_time">
        <span class="mplayer_curtime">00:00</span>
        <span class="m-join">/</span>
        <span class="mplayer_durtime">00:00</span>
      </div>
    </div>
    <audio id="myAudio" :src="article.music"></audio>
  </div>
</template>

<script>
export default {
name: "mp3",
props:['article'],
  data(){
    return {
      timer:null,
      audio:null,
      circle:null,

    }
  },
  mounted() {
    this.audio = document.getElementById('myAudio');
    this.circle = document.querySelectorAll('.m-circle .a')[0];

  },
  beforeDestroy() {
      clearInterval(this.timer)
  },
  methods:{
    play(){
      const currentTime = document.querySelector(".mplayer_curtime");
      const durationTime = document.querySelector(".mplayer_durtime");
      const musicbox =  document.querySelector('.music-box')
      if (this.audio.paused) {
        this.audio.play();

        const circumference = 2 * Math.PI * 160;
        musicbox.classList.add('mplaying');
        // 计时器实时更新进度
        this.timer = setInterval(() => {
          if (this.audio.ended) {
            // 音频播放结束
            musicbox.classList.remove('mplaying');
            currentTime.innerText = "00:00" ;
            this.circle.setAttribute(
                'stroke-dasharray', '0 999'
            );
          } else {
            currentTime.innerText =  this.formatTime(this.audio.currentTime);
            durationTime.innerText = this.formatTime(this.audio.duration);
            var step = circumference / this.audio.duration;
            var timeDisplay = Math.floor(this.audio.currentTime);
            this.circle.setAttribute(
                'stroke-dasharray',
                '' + timeDisplay * step + ' ' + circumference
            );
          }
        }, 100);
      } else {
        this.audio.pause();
        musicbox.classList.remove('mplaying');
      }
    },
    formatTime(time) {

      // 取整
      time = ~~time;
      var formatTime;
      if (time < 10) {
        formatTime = '00:0' + time;
      } else if (time < 60) {
        formatTime = '00:' + time;
      } else {
        var m = ~~(time / 60);
        if (m < 10) {
          m = '0' + m;
        }
        var s = time % 60;
        if (s < 10) {
          s = '0' + s;
        }
        formatTime = m + ':' + s;
      }
      return formatTime;
    }
  }
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}




.music-box {
  width: 500px;
  height: 500px;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0px auto 0;
}

.music-box img {
  width: 100%;
  border-radius: 50%;
  transition: all 0.36s ease;
  display: inline;
  animation: spin 46s infinite linear;
  animation-play-state: paused;
}

.music-box .mask {
  width: 316px;
  height: 316px;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
}

.mplayer {
  position: absolute;
  width: 60px;
  height: 60px;
  background-color: #ad986d;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  transform: translate3d(-50%, -50%, 0);
  z-index: 2;
  cursor: pointer;
}

.mplayer i {
  background-color: #000000;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: 2px;
  height: 10px;
  margin-left: -2px;
  transition: 0.36s ease;
}

.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
  line-height: 10px;
}

.mplayer i .before {
  width: 0;
  height: 0;
  border-color: transparent transparent transparent #ad986d;
  display: inline-block;
  border-width: 4px 6px 4px 6px;
  border-style: solid;
  color: #ad986d;
  margin-left: 2px;
  border-width: 5px 8px;
  margin-top: 0px;
  transition: 0.36s ease;
}

.mplayer i .before {
  border-left-color: #000;
}

.mplayer i .after {
  display: inline-block;
  width: 4px;
  height: 10px;
  opacity: 0;
  transition: 0.36s ease;
  border-left: 2px solid black;
  border-right: 2px solid black;
}

.loadingSvg{
  width: 40%;
  height: 40%;
}
.loadingSvg circle {
  r: 14;
  stroke: rgba(0, 0, 0, 0.7);
  stroke-dasharray: 87;
  stroke-dashoffset: 87;
  cx: 25;
  cy: 25;
  stroke-width: 2;
  fill: none;
}

.m-circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);

}
.m-circle svg{
  width: 200px;
  height: 200px;
}

.m_time span {
  display: block;
  text-align: center;
}

.m_time .mplayer_curtime {
  padding-top: 42px;
  font-size: 34px;
  color: #e1be78;
}

.m_time .m-join {
  display: none;
}

.m_time .mplayer_durtime {
  color: #ad986d;
}

.mplaying img {
  animation-play-state: running;
  -webkit-animation-play-state: running;
}

img {
  animation: spin 46s infinite linear;
  -webkit-animation: spin 46s infinite linear;
  animation-play-state: paused;
  -webkit-animation-play-state: paused;
}

.mplaying .mplayer i {
  margin-top: -9px;
  background-color: transparent;
}

.mplaying .mplayer i .before {
  opacity: 0;
}

.mplaying .mplayer i .after {
  opacity: 1;
}

.mplaying .loadingSvg circle {
  transition: 2s;
  stroke-dasharray: 87;
  stroke-dashoffset: 87;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes svgLoading {
  0% {
    stroke-dashoffset: 2;
  }

  100% {
    stroke-dashoffset: -39;
  }
}


</style>
